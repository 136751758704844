import * as React from "react";
import { SVGProps } from "react";
const Logo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width="1em"
    height="1em"
    viewBox="0 0 210 210"
    {...props}
  >
    <path
      d="M75.985 30.605c7.735-2.255 15.822-2.165 23.542-.636-3.231 3.288-6.723 11.834-9.976 14.786-19.14 1.125-19.451 1.878-31.338 13.53-6.08 4.464-11.393 20.31-11.143 30.027.332 4.505-11.176.742-10.033-4.919 1.793-9.677 2.942-25.445 8.211-33.438 7.27-9.519 19.805-16.386 30.737-19.35zm43.407 6.998c23.166 8.061 32.163 44.977 25.622 69.284-2.201 8.737-10.895 17.725-16.475 24.562-1.342-13.537-1.314-17.365-6.407-27.914 6.273-12.597 9.938-25.504 2.714-41.076-4.361-7.555-10.115-11.67-19.782-17 2.864-2.454 10.753-7.926 14.328-7.856zm-67.245 77.252c8.791 13.287 15.139 13.847 30.147 12.767 8.584-.123 18.554-4.783 27.061-8.805 2.471 8.047 2.881 11.465 2.994 22.155-7.272 3.647-18.203 8.95-26.2 8.415-10.208 1.007-20.852-2.725-29.367-8.545-3.86-2.638-7.309-6.365-10.413-9.858-5.655-6.385-5.536-10.643-8.192-19.038-1.338-3.759 10.95-2.593 13.97 2.909z"
      style={{
        fill: "#000",
        fillOpacity: 1,
        stroke: "#fff",
        strokeWidth: 11,
        strokeDasharray: "none",
        strokeOpacity: 1,
        paintOrder: "markers stroke fill",
      }}
    />
    <path
      d="M132.966 15.041c15.26-6.516 17.628-9.473 17.503 2.436-.436 13.778-3.445 30.135-9.14 42.718-3.1-4.324-13.319-10.583-9.908-18.22 1.644-4.23 6.601-18.189 4.516-17.2-16.765 8.465-26.34 15.9-41.388 34.662-2.682 3.331-12.664-3.534-10.045-7.204 8.39-10.428 11.856-16.925 22.52-24.805 7.384-5.581 15.905-8.544 24.193-11.876.583-.17 2.05-1.614 2.632-1.784zM28.656 57.406c2.74.107 20.63-6.669 19.344-2.396-2.799 3.993-4.885 14.126-10.396 14.503-5.104 1.53-13.487 3.865-18.308 6.296 11.808 15.098 23.098 18.973 40.678 21.606 1.923.277 5.91 12.741 1.276 12.536-18.152-.95-34.436-9.204-47.615-22.257A84.106 84.106 0 0 1 2.532 74.11c-1.928-2.508-1.61-3.585 1.544-5.529 2.69-1.657 8.035-3.36 9.388-4.852 5.064-2.588 12.915-5.052 18.205-6.956ZM62.7 55.069c16.795 3.01 30.451 6.982 41.468 20.897 2.042 2.47-5.767 10.817-8.227 9.332-10.397-8.968-28.903-19.01-42.355-19.622 2.172-3.726 4.968-9.5 9.114-10.607zM82.764 84.28c-3.814 16.913-4.498 17.344-.025 34.085 1.32 2.393-11.698 5.937-12.75.178-6.214-18.04-5.021-25.08.635-40.822 3.011-4.216 13.533 1.82 12.14 6.559zm40.808-17.568c1.065 4.507 3.22 9.54 1.073 13.126-11.276 14.772-23.155 23.568-40.115 28.033-1.968-.586-4.1-13.491 1.923-12.59 15.062-4.592 25.086-18.06 34.161-32.037 1.12-1.64 2.317 1.227 2.958 3.468zm-6.509 29.387c5.834 12.363 8.484 25.067 8.426 39.305.478 15.374-3 30.679-8.138 45.112-3.1-2.037-6.494-3.847-9.496-6.097-8.82-6.613-12.638-8.68-18.859-18.226-2.363-3.268 11.388-6.08 14.37-2.726 3.244 2.869 7.438 12.88 10.713 1.31 2.873-27.274-1.956-36.26-7.91-51.128-2.536-1.135 8.798-10.047 10.894-7.55z"
      style={{
        display: "inline",
        fill: "#000",
        fillOpacity: 1,
        stroke: "#fff",
        strokeWidth: 7.564,
        strokeDasharray: "none",
        strokeOpacity: 1,
        paintOrder: "markers stroke fill",
      }}
    />
    <path
      d="M51.928 36.096c-12.961 11.111-37.08 65.996-37.062 73.216l.046 17.149c.008 3.192 31.458-52.463 42.39-63.824 5.936-6.17 19.245-18.735 51.345-12.332 13.04 3.104 18.492 6.554 38.454 26.377 53.968 53.592 46.801 118.249 51.315 114.156 5.314-4.82 4.865-7.048 9.192-16.291 1.621-3.462-7.645-55.904-12.51-65.504-16.994-33.535-25.514-49.468-38.439-59.838-16.39-13.152-41.027-22.28-58.601-23.558-30.259-1.796-33.828-.097-46.13 10.45Z"
      style={{
        color: "#000",
        fill: "#000",
        fillOpacity: 1,
        stroke: "#fff",
        strokeWidth: 9,
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeDasharray: "none",
        strokeOpacity: 1,
        paintOrder: "markers stroke fill",
      }}
    />
    <path
      d="M79.799 98.284c-.39-8.331.65-12.497 3.905-22.39 7.81 2.082 14.65 3.317 20.248 10.867-7.03 7.94-12.307 10.872-24.153 11.523z"
      style={{
        fill: "#000",
        fillOpacity: 1,
        stroke: "none",
        strokeWidth: 8.99999,
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeDasharray: "none",
        strokeOpacity: 1,
        paintOrder: "markers stroke fill",
      }}
    />
  </svg>
);
export default Logo;
